import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const contactus = () => {
  return (
    <Layout>
      <SEO title='Contact Us' />
      <div class="c">
        <h1 class="center">Contact Us</h1>
        <p class="">
          Please submit your queries here{" "}
          <a href="mailto:syberxofficial@gmail.com" target="_blank">
            syberxofficial@gmail.com
          </a>
        </p>
        <p class="">
          Leave a message on the above email, we usually respond in 24-48 hours !
        </p>
      </div>
    </Layout>
  )
}

export default contactus
